<template>
  <v-alert class="mb-0 py-2 px-3" text :color="color" min-width="250px">
    <div class="ml-2">
      <div class="d-flex align-end justify-space-between">
        <h3>{{ nombreEmpresa }}</h3> <h6 class="ml-2">~ {{ nFacturas }} facturas</h6>
      </div>
      <v-divider class="my-2"></v-divider>
      <div class="text-right">{{ importe | eur }}</div>
    </div>
  </v-alert>
</template>

<script>
export default {
  props: {
    importe: Number,
    empresa: String,
    nFacturas: Number
  },
  data() {
    return {
      color: null,
    };
  },
  mounted() {
    this.color = this.$store.getters.getColoresEmpresas[this.empresa];
  },
  computed: {
    nombreEmpresa() {
      return this.empresa.charAt(0).toUpperCase() + this.empresa.substr(1).toLowerCase();
    }
  }
};
</script>
